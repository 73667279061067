.user-profile {

  text-shadow: 1px 1px 1px rgba(66,66,66,0.25);

  img {
    max-width: 4em;
    max-height: 4em;
    margin: 16px 5px 6px 20px;
    border-radius: 50%;
    border: solid 0.07em rgba(255,255,255,0.7);
    box-shadow: 3px 3px 3px rgba(66, 66, 66, 0.25);
  }


  .logout-link {
    color: #fff;
    padding: 4px;
    cursor: pointer;

    position: absolute;
    right: 20px;
    bottom: 12px;

    &:hover {
      text-shadow: 1px 1px 1px rgba(66,66,66,0.5);
    }
  }


  .user-name {
    bottom: 12px;
    color: #fff;
    font-weight: bold;
    left: 5px;
    min-width: 4em;
    padding: 4px;
    position: absolute;
    text-align: center;
  }

}


@include mq-md {
  .user-profile {

    img {
      margin: 10px 15px 10px 8px;
      max-width: 5em;
      max-height: 5em;
    }

    .user-name {
      left: 20px;
    }
  }

}
