
.input-field {
//
//TODO: use angular built-in classes here...
  .invalid {
    // background: #f90;
    border-color: $colour-negative;
  }
  .voucher-invalid-msg {
    font-size: $text-small;
  }
}
