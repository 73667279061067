.month-selector {
  text-align: center;
  background-color: #f5f5f5;
  padding: 10px;
  box-shadow: inset 0px -10px 15px -10px rgba(0,0,0,0.1);

  &.month-selector-fullwidth {
    background: $background-secondary;
    height: 2.5em;
    line-height: 2.5em;
    margin-bottom: .75em;
    position: relative;
    text-align: center;
    width: 100%;


    .month-selector-previous,
    .month-selector-next {
      position: absolute;
    }

    .month-selector-previous {
      left: 0;
    }

    .month-selector-next {
      right: 0;
    }

  }

  .month-selector-name {
    display: inline-block;
    min-width: 8em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    text-align: left;
    font-size: 16px;
  }

  p {
    text-align: left;
    font-size: 16px;
  }

  .button-clear,
  .button-clear:hover {
    color: #6f6f6f;
  }

}
