
.add-margin-top {
  margin-top: $default-top-margin !important;
}


.suppress-spacing-bottom {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.suppress-margin-bottom {
  margin-bottom: 0 !important;
}

.suppress-padding-bottom {
  padding-bottom: 0 !important;
}

.force-width-auto {
  width: auto !important;
}
