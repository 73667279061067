.finn-promo {
  /*background: url('/images/promo/finn-logo.png') no-repeat left top, url('/images/promo/finn-mounted-abstract.png') no-repeat right bottom;*/
  background: url('/images/promo/finn-logo.png') no-repeat left 10px, url('/images/promo/finn-mounted-abstract.png') no-repeat right bottom;
  background-color:  #2f4562;

  padding: $padding-base-vertical $padding-base-horizontal;

  p.promo-headline {
    margin-top: 0px;
    font-weight: bold;
  }
}
