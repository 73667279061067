.highlight {
  color: $text-secondary-colour;
  font-weight: bold;
}

.heading-subtle {
  font-size: 100%;
  font-weight: normal;
  opacity: .9;
}

.heading-centre {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-tiny {
  font-size: $text-tiny;
}

.text-small {
  font-size: $text-small;
}

.text-medium {
  font-size: $text-medium;
}

.text-subtle {
  color: $text-subtle;
}
