.bc-backdrop {
  background: darken($background-secondary, 10%);
  //TODO: test these settings...
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}
