//NOTE: in principle this file should not contain *any* css rules.
// Instead it should contain only import statements - e.g. for components

// @import './../../node_modules/purecss/build/pure';
// @import './../../node_modules/purecss/build/grids-responsive';

////////////
// VENDOR //
////////////
// NOTE: inmporting these here may have a significant impact on build times...
// It may be better to create a separate file for vendor CSS and concat on prod build.
// NOTE: file sizes below are for minified file *with*sourcemaps

// PURE
// see: http://purecss.io/
@import './../../node_modules/purecss/build/base';
@import './../../node_modules/purecss/build/buttons';
@import './../../node_modules/purecss/build/forms';
@import './../../node_modules/purecss/build/tables';
@import './../../node_modules/purecss/build/grids';
@import './../../node_modules/purecss/build/grids-responsive';
//~98kB

// // FONT-AWESOME
// TODO: selectively import required partials...
@import './../../node_modules/font-awesome/scss/font-awesome'; // ~ 160kB!!!


@import './../vendor-cache/fonts/webfonts'; // ~1kB :)

@import './../../node_modules/angularjs-slider/dist/rzslider.scss';
@import './../../node_modules/angular-material/angular-material.scss';



/*!
---
BIKE CITIZENS CSS...
*/


// The following CSS ~ 90-100kB
//////////////
// PARTIALS //
//////////////
@import './config';
@import './mixins';

@import './partials/typography';

@import './layout/layout';




@import './partials/lists';
@import './partials/forms';
@import './partials/buttons';
@import './partials/components';
@import './partials/appstore_sprites';


@import './layout/overrides';

@import './layout/app-layout';
@import './layout/web-layout';
@import './layout/iframe-layout';
@import './layout/media-tiny';
@import './layout/media-sm';
@import './layout/media-md';
@import './layout/media-lg';



// styling that may need to be moved into a suitable component etc...
@import './partials/temp';

// angular components
@import './../components/index';


// import RZA stylesheets
// @import './../routes/bespoke-campaigns/rza/index';
@import './../routes/bc-voucher/bc-voucher';
