// general purpose mixins can be added here - i.e. commonly used combinations of rules




// shouldn't be required and not currently used
@mixin clearfix() {
  &::after {
    clear: both;
    content: ''; // Older browser do not support empty content
    display: table;
  }
}

@mixin hideme() {
  display: none;
  visibility: hidden;
}

@mixin showme() {
  display: block;
  visibility: visible;
}

//HACK: limited support:
// see: https://developer.mozilla.org/en-US/docs/Web/CSS/::placeholder#Browser_compatibility
@mixin placeholder() {
  ::-webkit-input-placeholder {
    // WebKit, Blink, Edge
    @content;
  }
  :-moz-placeholder { // Mozilla Firefox 4 to 18
     @content;
  }
  ::-moz-placeholder { // Mozilla Firefox 19+
     @content;
  }
  :-ms-input-placeholder { // Internet Explorer 10-11
     @content;
  }
}

//media queries
//tiny and above
@mixin mq-tiny() {
  @media screen and (max-width: 35.5em) {
    @content;
  }
}

// small and above
@mixin mq-sm() {
  @media screen and (min-width: 35.5em) {
    @content;
  }
}

// less than or equal to small
// NOTE: duplicates mq-tiny above
@mixin mq-lt-sm() {
  @media screen and (max-width: 35.5em) {
    @content;
  }
}

//etc...
@mixin mq-md() {
  @media screen and (min-width: 48em) {
    @content;
  }
}

@mixin mq-lt-md() {
  @media screen and (max-width: 48em) {
    @content;
  }
}

@mixin mq-lg() {
  @media screen and (min-width: 64em) {
    @content;
  }
}

@mixin mq-lt-lg() {
  @media screen and (max-width: 64em) {
    @content;
  }
}

@mixin mq-xl() {
  @media screen and (min-width: 80em) {
    @content;
  }
}


@mixin mq-height-lt-min() {
  @media screen and (max-height: 34em) {
    @content;
  }
}


@mixin mq-height-min() {
  @media screen and (min-height: 34em) {
    @content;
  }
}

@mixin mq-height-mid() {
  @media screen and (min-height: 48em) {
    @content;
  }
}




@mixin dismiss-button-styling() {
  background: $secondary-button-background;
  color: $secondary-button-colour;
  height: 1.5em;
  opacity: .8;
  padding: .2em;
  position: absolute;
  right: .5em;
  top: .3em;
  width: 1.5em;

  &:hover,
  &:focus,
  &:active {
    color: darken($secondary-button-colour, 15%);
  }
}
