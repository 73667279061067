.bc-prompt {


  margin-top: 1em;

  .prompt-dismiss {
    @include dismiss-button-styling();
  }

  .prompt-message {
    //
  }

  .prompt-buttons {
    padding: .5em 0;
    width: auto;

    .pure-button:last-child {
      margin-right: 1em;
    }
  }

}


.bc-prompt-popover,
.bc-prompt-static {

  border-radius: $border-radius-standard;
  line-height: 1.5em;

  position: relative;

  // default icon settings
  &::before {
    color: $prompt-icon-colour;
    font-family: FontAwesome;
    font-size: $text-medium;
    left: .5em;
    position: absolute;
    text-align: left;
    top: .45em;
    width: 2em;
  }



  &.prompt-info {

    &::before {
      content: '\f05a';

    }
  }

  &.prompt-warn {

    &::before {
      color: $prompt-warn-background;
      content: '\f071';
    }
  }


  &.prompt-success {

    &::before {
      color: $prompt-success-background;
      content: '\f00c';

    }
  }


  &.prompt-error {

    &::before {
      color: $prompt-error-background;
      content: '\f06a';
    }

  }




  &.prompt-confirm {

    &::before {
      content: '\f059';
    }
  }


}

// .prompt-buttons {
//   //
// }



.bc-prompt-static {

  background: $prompt-info-background;
  margin-bottom: $default-bottom-margin;

  .prompt-message {
    padding: .75em 2.5em .75em 2.75em;
    text-align: left;
  }


  .prompt-buttons {
    margin: 0 1.5em .75em;
  }

}

.popover {

  .bc-prompt-popover {

    padding: 0;

    .bc-prompt {
      margin-top: 0;
      width: 100%;
    }

    .prompt-message {
      margin-bottom: 4em;
      padding: 1.75em 2.5em 1.75em 3.5em;
      text-align: left;
    }

    .prompt-message-clear-padding {
      // reverses horizontal padding set above
      margin-right: -2.5em;
      margin-left: -3.5em;
    }

    .prompt-buttons {
      border-top: solid $border-primary-colour $border-width;

      button {
        margin: .5em .5em .5em 0;
      }

      .button-full-width {
        background: $popover-fullwidth-button-background;
        border-radius: 0;
        color: $popover-fullwidth-button-color;
        margin: auto 0 0;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }

  .prompt-confirm {
    .prompt-buttons {
      text-align: right;
    }
  }
}

@include mq-tiny {
  .popover {

    .bc-prompt-popover {

      .prompt-message {
        padding: 3.75em 1.5em 1.75em;
      }
    }
  }
}
