@include mq-md {


  // hidden element - tracks current active media query
  #bc-resp-div {
    width: 480px;
  }


  .hidden-md {
    @include hideme();
  }

  .hidden-sm {
    @include showme();
  }


  .hidden-tiny-sm {
    @include showme();
  }

  .hidden-tiny-md {
    @include hideme();
  }

  // for consistency - and we may introduce larger sizes later ;)
  .hidden-tiny-lg {
    @include hideme();
  }


  .centre-md {
    margin: 0 auto;
  }


}
