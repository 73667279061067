.bc-chip {

  padding: 1.5em 0;

  &.micro-chip {
    background: $chip-icon-background;
    border-radius: 4px;
    color: $chip-icon-colour;
    display: inline-block;
    margin: 0 2px 4px;
    padding: .25em .5em;

    .bc-chip-no-icon {
      margin: 0;
    }

    &.bc-chip-subtle {
      background: $background-subtle;
    }
  }

  .bc-chip-icon {
    background: $chip-icon-background;
    border-radius: 50%;
    color: $chip-icon-colour;
    display: block;
    float: left;
    font-weight: bold;
    height: 3em;
    line-height: 3em;
    margin: 0 1em 0 .25em;
    text-align: center;
    width: 3em;
  }

  .bc-chip-icon-invert {
    color: lighten(invert($chip-icon-colour), 15%);
  }

  .bc-chip-icon-img {
    height: auto;
    max-width: 2em;
    vertical-align: middle;
    width: 100%;
  }

  .bc-chip-icon-text {
    display: inline-block;
    max-width: 2.1em;
    overflow: hidden;
  }


  .bc-chip-content {
    margin: .2em 0 0 4.25em;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .bc-chip-no-icon {
    margin: .2em 0 0 .25em;
  }


  .bc-chip-label {
    display: block;
    font-size: 90%;
    opacity: .8;
  }



  // modifiers
  .bc-chip-subtle {
    background: darken($background-subtle, 10%);
  }

  .bc-icon-transparent {
    background: transparent;
  }

  .bc-chip-image-big {
    height: 3.5em;
    max-width: 3.5em;
    width: 3.5em;
  }

  .bc-chip-image-medium {
    height: 3em;
    max-width: 3em;
    width: 3em;
  }

  &.bc-chip-big {

    .bc-chip-icon {
      font-size: 110%;
    }

    .bc-chip-content {
      margin: .25em 0 0;
    }

    .bc-chip-value {
      font-size: 130%;
    }

    .bc-chip-label {
      font-size: 100%;
    }

  }

}




@include mq-tiny {

  .bc-chip {
    .bc-chip-icon {
      font-size: 70%;
    }

    .bc-chip-content {
      // font-size: 100%;
      margin-left: 3.25em;
    }

    .bc-chip-no-icon {
      margin-left: .25em;
    }

    .bc-chip-label {
      font-size: 80%;
    }
  }

}
