.prize-list {
  padding-top: 20px;
  background-color: #f5f5f5;

  .prize {
    background-color: #ffffff;
    margin-top: 30px;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,.5);

    .title {
      padding: 10px 12px;
      border-bottom: 1px solid #cdcdcd;
      text-align: left;

      .rank {
        font-weight: bold;
        font-size: 11pt;
      }

      .name {
        float: right;
        font-weight: bold;
        font-size: 10pt;
        color: #737373;
      }
    }

    .details {
      display: block;
      width: 100%;
    }

    .description {
      display: inline-block;
      width: 65vw;
      height: 33vw;
      line-height: 33vw;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      vertical-align: bottom;

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }

    .logo {
      // background-color: red;
      display: inline-block;
      width: 33vw;
      height: 33vw;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-left: 1px solid #cdcdcd;
    }
  }

  .more {
    padding: 40px 20px;
    font-weight: bold;
    text-align: center;
    line-height: 28px;
    font-size: 12pt;
  }
}
