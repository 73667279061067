.box-plot-chart {
  position: relative;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0 10px;

  .chart {
    position: relative;
    height: 92%;

    span {
      line-height: 24pt;
    }

    .max,
    .mid,
    .min {
      position: absolute;
      bottom: 0;
      transition: height 1s;
    }

    .max {
      height: 100%;
      width: 80%;
      left: 10%;
    }

    .mid {
      width: 100%;
      height: 0%;
    }

    .min {
      width: 60%;
      height: 0%;
      left: 20%;
      color: white;

      &.small {
        span {
          position: relative;
          top: -30px;
        }
      }
    }
  }

  .label {
    margin-top: 10px;
  }
}
