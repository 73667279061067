.sponsor-list {
  padding: 8px 0 5px;
  text-align: center;
  width: 100%;

  .sponsor {
    display: inline-block;
    width: 100px;
    height: 50px;
    padding: 0 10px;
  }

  @media screen and (max-width: 359px) {
    @for $i from 3 through 4 {
      .sponsor:first-child:nth-last-child(#{$i}),
      .sponsor:first-child:nth-last-child(#{$i}) ~ .sponsor {
        width: 240px / $i;
        height: 120px / $i;
        padding: 0 5px;
      }
    }
  }

  .logo {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.opaque .sponsor-list {
  background-color: rgba(0,0,0,.5);
}

.white .sponsor-list {
  background-color: #ffffff;
}
