@include mq-sm {


  // hidden element - tracks current active media query
  #bc-resp-div {
    width: 355px;
  }

  .hidden-tiny {
    @include showme();
  }


  .hidden-sm {
    @include hideme();
  }

  .hidden-tiny-sm {
    @include hideme();
  }

  .hidden-tiny-md {
    @include hideme();
  }

  // for consistency - and we may introduce larger sizes later ;)
  .hidden-tiny-lg {
    @include hideme();
  }

  .padding-small-sm {
    padding: .5em;
  }

  .set-max-height-sm {
    max-height: 420px;
    overflow-y: scroll;
  }


}
