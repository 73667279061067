.bc-voucher {
  .pure-button {
    text-transform: none;
  }

  input,
  select {
    max-width: auto;
    width: 100%;
  }


  @mixin red-button() {
    display: inline-block;
    background-color: $primary-button-background;
    color: $primary-button-colour;
    border-radius: 1em;
    border: none;
    padding: 0.2em 0.6em;
    margin: 0 0.2em 0.2em 0;
    cursor: pointer;
    font-size: 90%;
  }
  .bc-city-list-results {
    padding-top: 1em;
    .auto-completion-details {
      display: inline;
      @include red-button();
    }
  }

  .auto-completion-list {
    margin: 0;
    padding: 0;
    display: block;
    clear: both;

    .autocomplete-more {
      display: none;
    }

    li {
      list-style-type: none;
      @include red-button();
    }
  }

  .auto-completion-details {
    color: $text-subtle;
    font-size: $text-small;
    padding: .5em 1em;
    text-align: right;
  }

  #voucher-input-placeholder {
    position: relative;
  }
  #formatted-voucher-code {
    border: solid $border-width $border-primary-colour;
    border-radius: 3px;
    position: relative;
    color: $text-secondary-colour;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 1.4em;
    line-height: 1.7em;
  }
}

#voucher-input {
  text-align: center;
}

body {
  color: #284156;
  font-family: "Lato", sans-serif;
}

input {
  color: #707070;
}

a {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-style: solid;
}

.redemption-success {
  padding: 2em;

  background: url('/images/icons/bc-white.svg') calc(100% - 1.5em) 1.5em no-repeat, linear-gradient(to bottom, #EA3539 50%, transparent 50%);

  color: white;

  .congrats {
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.5em;
  }

  .success {
  }

  .city-name {
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: $text-large;
  }

  .download {
  }

  ul.premium-features {
    padding-left: 0;
  }
  ul.premium-features li {
    list-style-type: none;
    padding: 5px 0;
  }
  ul.premium-features li:before {
    content: '✔';
    margin-right: 15px;
    color: #EA3539;
  }

  .welcome-title {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
  }
}
