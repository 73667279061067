.growth-chart {
  position: relative;
  margin-bottom: 11px;
  height: 180px;
  width: 300px;

  .x-axis {
    position: absolute;
    display: block;
    width: 100%;
    bottom: -8px;
    padding-top: 8px;
    left: 0;
    display: flex;
    justify-content: space-between;
  }
}
