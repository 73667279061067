.campaigns-leaderboard {
  padding: 10px;

  h3 {
    font-size: 14pt;
  }

  table {
    width: 100%;

    td,
    th {
      padding: 10px;
      border-bottom: 1px solid #eeeeee;
    }

    th {
      font-weight: normal;
    }

    td {
      &.rank,
      &.name,
      &.metric {
        font-size: 14pt;
      }
    }

    .rank {
      text-align: center;
    }

    .name {
      text-align: left;
    }

    .metric {
      text-align: right;
    }

    tfoot {
      .heading {
        padding: 20px 0 0;
      }

      .rank,
      .name,
      .metric {
        color: #42d4f4;
        font-weight: bold;
      }
    }
  }

}
