.challenge {
  padding: 10px 0;
  font-size: 12pt;
  box-shadow: 0 5px 10px 0px rgba(0,0,0,.5);
  margin-bottom: 20px;

  .html-block {
    padding: 0 10px 20px;

    /* here comes a hack to use an image as external link as this was not supported by BC App */
    .external-link {
      position: relative;

      a {
        top: 0;
        left: 0;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .details {
    padding: 0 10px;

    .description {
      white-space: pre-line;
      line-height: 16pt;
    }
  }

  img {
    max-width: 100%;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  .bc-progress-bar {
    .subText {
      text-align: left;
    }
  }

  .countdown {
    color: #9c9c9c;
    text-transform: uppercase;
    font-weight: bold;
    margin: 30px 0;
  }

  form {
    font-weight: bold;

    button {
      background-color: #6cccf7;
    }
  }

  .actions {
    border-top: 1px solid #e4e4e4;

    a {
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 20px 10px;
      display: block;
    }

    &.ranking {
      border-bottom: 1px solid #e4e4e4;
      margin: 0 -10px 20px;
    }
  }

  .icon {
    display: inline-block;
    width: 20px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .icon-ranking {
    background-image: url('/images/campaigns/bremen-moon/Icon_02_Ranking.svg');
    margin-bottom: -3px;
  }

  .icon-video {
    background-image: url('/images/campaigns/bremen-moon/Icon_04_Video.svg');
    margin-bottom: -3px;
  }

  .icon-prizes {
    background-image: url('/images/campaigns/bremen-moon/Icon_05_Prizes.svg');
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
