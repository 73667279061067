a {
  color: $link-primary-colour;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;


  &.no-decoration {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

}


[ng-click] {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.info {
  color: $text-subtle;
}

// BOX
.box {
  //box-shadow: $box-shadow-bottom $box-shadow-light, $box-shadow-top $box-shadow-light ;
  border-bottom: $border-width solid $background-secondary;
  border-top: $border-width solid $background-secondary;

  margin: $default-margin;

  .box-heading {
    color: $box-heading-colour;
    font-size: 110%;
    margin-top: 0;
  }

  .box-subheading {
    color: $box-heading-colour;
    font-size: 100%;

  }

}

.web-context .box {
  box-shadow: none;
  margin: $default-margin;
}

.box-bottom-border {
  border-bottom: solid $border-width $border-secondary-colour;
}

// TOP PANEL
// panel that takes the full width at top of the page
.bc-top-panel {
  background: $background-subtle;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 140px;
  padding: 0;
  position: relative;

}

@include mq-md {
  .bc-top-panel {
    height: 160px;
    padding: 12px 20px 7px;
  }
}



.bc-img-circle {
  border-radius: 50%;
}

.bc-logo {

  &::before {
    content: url('../images/icons/bc.svg');
    display: inline-block;
    height: 2.5em;
    margin-right: .5em;
    vertical-align: middle;
    width: 2.5em;
  }

}

.activity-indicator {
  background: $background-secondary;
  border-radius: 50%;
  box-shadow: 0 0 6px $box-shadow-mid;
  color: $text-secondary-colour;
  display: none;
  height: 3em;
  left: 50%;
  line-height: 3em;
  margin-left: -1.5em;
  margin-top: -1.5em;
  position: fixed;
  text-align: center;
  top: 3.5em;
  width: 3em;

  i {
    margin-top: .25em;
  }

  &.active {
    display: block;
  }

}



.team-admin-user-list,
.team-admin-team-list,
.team-search-results,
.teams-list {

  li {
    background: $background-secondary;
    border-bottom: solid $border-width $border-primary-colour;
  }

  li:first-child {
    border-top: solid $border-width $border-primary-colour;
  }

}

.team-admin-user,
.team-admin-invite,
.team-admin-request,
.team-admin-result,
.team-list-view {

  @include clearfix();

  .team-admin-email,
  .team-admin-team-name {
    display: inline-block;
    font-weight: bold;
    min-height: 2em;
    overflow: auto;
    padding: .75em 1em 0;
    width: auto;
  }

  .team-admin-message {
    color: lighten($text-primary-colour, 20%);
    font-weight: normal;
    padding: .25em 1em 0;
  }

  .team-admin-buttons {
    display: inline-block;
    float: right;
  }

  .team-admin-team-dist {
    float: right;
    font-weight: bold;
    padding: .75em 1em 0;
  }

  .team-admin-team-members {
    display: inline-block;
    overflow: auto;
    padding: .75em 1em;
  }

  .team-request-status {
    float: right;
    font-weight: bold;
    padding: .75em 1em;
  }
}
