.sharing {
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #e4e4e4;

  a {
    display: block;
    padding: 10px;
    text-decoration: none;
  }

  span {
    display: inline-block;
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
  }

  img {
    max-height: 42px;
    width: auto;
  }

  &:active,
  &:focus {
    background-color: #F4F3F4;

    img {
      transform: scale(0.97,0.97);
      background-color: rgb(256,242,242);
    }
  }

  .overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
    cursor: pointer;

    .content {
      position: absolute;
      text-align: left;
      width: 95%;
      top: 50%;
      left: 50%;
      color: white;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
    }

    .center {
      text-align: center;
    }

    .button {
      padding: 5px 14px;
      border: 2px solid white;
      border-radius: 5px;
      text-transform: none;
    }
  }
}
