.team-details {

  .team-members {

    margin-top: $default-top-margin;

    li {
      border-bottom: solid $border-width $border-secondary-colour;

      &:first-child {
        border-top: solid $border-width $border-secondary-colour;
      }
    }

    .team-member-distance {
      text-align: right;
    }
  }


}


@include mq-sm {

  .team-details {
    .team-members {
      margin: $default-top-margin 1em 0 3em;
    }
  }
}
