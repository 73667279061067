.iframe-context,
.web-context.iframe-context {

  .iframe-hidden {
    @include hideme();
  }

  .content-wrapper {
    background: inherit;
    margin: 0 auto;

    padding-bottom: 0;
  }




  .box {
    background: $background-primary;
    margin: $default-top-margin auto $default-bottom-margin;
    max-width: $max-width-md;
  }

  .box-wide {
    max-width: $max-width-lg;
  }

  .main {
    margin: 0 auto;
  }

  .space {
    display: block;
    height: 90px;
  }


}


@include mq-sm {
  .iframe-context,
  .web-context.iframe-context {
    //HACK: 'resolves' issue with popover content appearing far down the screen on long iframe content...
    .popover {
      .popover-contents {
        top: 15%;
        transform: translate(-50%, -15%);
      }
    }
  }
}
