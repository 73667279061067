.web-context {

  .web-hidden {
    @include hideme();
  }

  .content-wrapper {
    // background: #f90;
  }

  .footer {
    padding: 1em 3em;
    text-align: right;

    .nav-button {
      margin-left: 1em;
    }

  }

  .box {
    box-shadow: none;
    margin: $default-margin;

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $heading-font;
      font-weight: 500;
    }
  }

  .mega-button {
    display: block;
    margin-left: 5%;
    position: relative;
    width: 85.5%;
  }

  .navigation-list {
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    li {
      cursor: pointer;
      display: inline-block;
      height: 3em;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    button {
      height: 3em;
      width: 100%;
    }

  }

}

@include mq-tiny {
  .web-context {
    .footer {
      font-size: 80%;
    }

    .floating-box {
      .pure-form .main {
        padding: 1em;
      }
    }

  }
};

@include mq-lg {
  .web-context {
    .mega-button {
      margin-left: auto;
      width: auto;
    }
  }

  .floating-box {
    .mega-button {
      width: 100%;
    }
  }
}
