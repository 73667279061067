.accomplishments {
  display: block;
  background-color: #F5F5F5;

  overflow-x: hidden;

  &.all {
    min-height: 420px;
    min-height: 100vh;

    .most-recent {
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  .most-recent {
    display: flex;
    justify-content: space-around;
    width: 100%;
    box-shadow: 2px 3px 5px #aaaaaa;
    background-color: #ffffff;
    z-index: 99;

    .recent-accomplishment {
      width: 64px;
      width: 20vw;
      padding-top: 10px;

      .coin {
        height: 38px;
        height: 12vw;
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: top center;
        position: relative;

        .icon-new {
          right: 0;
          bottom: -20%;
          width: 40%;
          height: 40%;
        }
      }

      .slot {
        display: block;
        height: 12px;
        height: 4vw;
        margin-top: -1px;
        background-image: url('/images/elements/coin_slot.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
      }
    }
  }

  .icon-new {
    position: absolute;
    background-image: url('/images/elements/new.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .anchor {
    display: block;
    position: relative;
    top: -64px;
    top: -20vw;
    visibility: hidden;
  }

  .categories {
    padding: 57px 0;
    padding: 18vw 0;

    .category {
      background-color: #FFFFFF;
      margin-top: 35px;
      box-shadow: 2px 3px 5px #aaaaaa;
      height: auto;

      .head {
        color: #58B8BB;
        position: relative;

        h3 {
          margin: 0;
          padding: 20px 10px 20px 12px;
          padding: 20px 10px 20px 4vw;
          font-weight: normal;
          display: inline-block;
          white-space: nowrap;

          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 10px -3px 0;
          }

          .icon-plus {
            background-image: url('/images/icons/plus.svg');
          }

          .icon-minus {
            background-image: url('/images/icons/minus.svg');
          }
        }

        span.count {
          position: absolute;
          right: 0;
          top: 0;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 20px 20px 10px 5px;
        }

        .icon-new {
          right: 5px;
          top: 30%;
          width: 30px;
          height: 26px;
        }
      }

      .progress {
        height: 160px;
        height: 50vw;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;

        .inner {
          width: 80%;
          height: 100%;
          margin-left: 10%;
          position: relative;
        }

        .mover {
          background-repeat: no-repeat;
          height: 80px;
          height: 25vw;
          width: 80px;
          width: 25vw;
          position: absolute;
          bottom: 29px;
          bottom: 9.2vw;
          margin-left: -32px;
          margin-left: -10vw;
        }

        .curtain {
          background-size: cover;
          width: 120%;
          height: 78.6%;
          position: absolute;
          background-repeat: no-repeat;
          margin-left: -16px;
          margin-left: -5vw;
        }

        .text {
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 9px 0;
          padding: 3vw 0;
          color: #ffffff;
          text-align: center;
        }

        .mover,
        .curtain {
          transition: left 2s ease-in-out;
          left: auto;
        }

        &.ng-hide {
          .mover,
          .curtain {
            left: 0 !important;
          }
        }

        .statistics-button {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 45px;
          height: 28px;
          background-image: url('/images/elements/statistics.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .badges {
        .icon-new {
          right: 5px;
          top: 35%;
          width: 10%;
          height: 30%;
        }

        .accomplishment,
        .accomplishment-group {
          position: relative;
          display: block;
          padding: 9px;
          padding: 3vw;
          border-top: 1px solid #AAAAAA;
          height: 108px;
          height: 34vw;
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;

          h4 {
            position: relative;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.7);
            padding: 8px 15px 8px 10px;
            border-radius: 5px;
            margin: 0;
          }

          p {
            position: relative;
            margin-top: 6px;
            margin-top: 2vw;

            span {
              font-size: 10pt;
              display: inline-block;
              background-color: rgba(255, 255, 255, 0.7);
              padding: 5px 10px;
              border-radius: 5px;
            }
          }

          .text {
            position: absolute;
            top: 9px;
            top: 3vw;
            left: 9px;
            left: 3vw;
            width: 256px;
            width: 80vw;
            transition: transform .5s ease-out;
            z-index: 20;
          }
        }

        .accomplishment-group {
          .icons {
            z-index: 3;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 40%;
            padding: 9px;
            padding: 3vw;
            box-sizing: border-box;
          }

          .badge {
            display: inline-block;
            height: 70%;
            width: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            box-sizing: border-box;
            transition: transform 0.5s ease-out;
            transform: scale(1);

            &.active {
              transform: scale(1.2);
            }

            .icon-new {
              top: auto;
              bottom: -9%;
              left: 0;
              width: 100%;
              height: 20%;
            }
          }

          .indicators {
            position: absolute;
            display: block;
            width: 100%;
            bottom: 5px;
            left: 0;
            text-align: center;

            .point {
              display: inline-block;
              width: 4px;
              height: 4px;
              border: 1px solid #000;
              border-radius: 50%;
              margin: 0 1px;
              transition: background-color .4s;

              &.active {
                background-color: #000;
              }
            }
          }
        }

        .accomplishment {
          background-size: 50%;
          background-position: center;
          background-repeat: no-repeat;

          &.locked {
            background-size: 64px;
            background-size: 20vw;
            background-position: right;
            background-origin: content-box;
          }
        }
      }

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        color: #fff;
        background-color: rgba(40,65,86,0.9);

        .close {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 20px;
          height: 20px;
          background-image: url('/images/elements/x.svg');
          background-size: contain;
        }

        .badges {
          padding-top: 100px;
          padding-top: 20vh;

          .badge {
            display: inline-block;
            width: 128px;
            width: 40vw;
            height: 128px;
            height: 40vw;
            position: absolute;
            transition: transform 0.5s ease-out, left 0.5s ease-out;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .text {
          display: block;
          position: absolute;
          top: 250px;
          top: 55vh;
          left: 0;
          text-align: left;
          width: 100%;
          transition: all .4s ease-out;

          h3,
          p {
            padding: 0 15px;
          }

          .small-print {
            text-align: left;
            padding-top: 15px;
            font-size: 70%;
          }
        }

        .indicators {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: #e5e5e5;
          box-shadow: inset 0px 7px 8px -5px rgba(0,0,0,0.75);
          text-align: center;
          padding: 5px 5px 0;

          .point {
            display: inline-block;
            border: 1px solid black;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: 3px;
            transition: background-color .4s;

            &.active {
              background-color: #000;
            }
          }
        }
      }


    }

    .statistics-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba(127,211,216,0.9);
      overflow-y: auto;

      h2 {
        color: #ffffff;
        text-align: center;
        margin: 30px 0 20px;
        margin: 15vh 0 10vh;
      }

      .statistics-element {
        background-color: #ffffff;
        margin: 30px 0;
        padding: 18px 0;
        box-shadow: 2px 3px 5px #aaaaaa;
      }

      .badge {
        width: 30px;
        height: 30px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 30%;
        vertical-align: middle;
      }

      .title {
        width: 45%;
      }

      .value {
        width: 20%;
      }

      .title,
      .value {
        display: inline-block;
        color: #58b8bb;
      }
    }
  }
}
