.bc-city-list {


  .bc-city-list-results {
    min-height: 40px;
    width: 90%;
  }

}


@include mq-lg {
  .bc-city-list {

    .bc-city-list-results {
      min-height: 160px;
    }

  }

}
