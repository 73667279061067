.bc-ident {

  // background: #333;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  width: 100%;


  .bc-ident-logo {
    border-spacing: 0;
    display: table-cell;
    margin-left: auto;
    text-align: right;
    img {
      height: 116px;
      width: 116px;

    }
  }

  .ident-heading {
    color: $primary-button-background;
    display: table-cell;
    font-size: $text-medium;
    font-weight: bold;
    margin-right: auto;
    vertical-align: middle;
  }

}
