@include mq-lg {

  // hidden element - tracks current active media query
  #bc-resp-div {
    width: 640px;
  }


  .hidden-lg {
    @include hideme();
  }


  .hidden-md {
    @include showme();
  }

  .hidden-tiny-md {
    @include showme();
  }

    // for consistency - and we may introduce larger sizes later ;)
  .hidden-tiny-lg {
    @include hideme();
  }


  .centre-lg {
    margin: 0 auto;
  }



  .content-wrapper {
    padding-bottom: 0;
  }

  .mobile-sticky {
    position: inherit;
  }




}
