.bc-progress-bar {
  text-align: center;
  padding: 10px;
  color: #ffffff;

  .outer {
    border: 2px solid white;
    border-radius: 7px;
    position: relative;
  }

  .inner {
    background: rgba(255,0,0,0.5);
    border-right: rgba(255,255,255,0.5);
    height: 20px;
  }

  .progressText {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
  }
}
