.dashboard-person-ranking {

  .ranking-overall {


    .ranking-qualifier {
      font-size: 80%;
      opacity: $opacity-half;
    }
  }

  .ranking-dir-up {
    color: $colour-positive;
  }

  .ranking-dir-down {
    color: $colour-negative;
  }


}
