html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

.content-wrapper {
  background: $background-primary;

  header,
  .main {
    padding: 1em 3em;
  }

}


// hidden element - tracks current active media query
// width is updated in media query partials
#bc-resp-div {
  margin: 0;
  padding: 0;
}


.block-centre {
  display: block;
  margin: 0 auto;
}


.align-centre {
  text-align: center;
}


//TODO: make this work in all applicable cases
//e.g. look at flexbox
.align-right {
  // assumes flexbox...  so may not work on older browsers
  justify-content: flex-end;
  text-align: right;
}


.add-margin {
  margin: $default-margin;
}

.add-margin-bottom {
  margin-bottom: $default-bottom-margin;
}

.add-padding-top {
  margin-top: $padding-base-vertical;
}

.padding-small {
  padding: .5em;
}


.max-width-sm {
  max-width: $max-width-sm;
}

.max-width-md {
  max-width: $max-width-md;
}

.max-width-lg {
  max-width: $max-width-lg;
}


.set-max-height-all {
  max-height: 264px;
  overflow-y: scroll;
}




.hideme {
  @include hideme();
}


.attach-bottom-wrapper {
  border-collapse: collapse;
  display: table;
  height: 100%;
}

.attach-bottom {
  display: table-row;
  height: 1px;
  vertical-align: bottom;
}


.fixed-top {
  box-shadow: $box-shadow-bottom $box-shadow-mid;
  margin: 0 auto !important;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: $zindex-4;
}


@include mq-md {
  .fixed-top {
    left: 15%;
    max-width: 70%;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

// - - -
//TODO: for demo purposes.  Don't pollute CSS file with cruft :P
.rsp-demo {
  color: #fff;
  font-weight: bold;
  margin-bottom: .5em;

  span {
    display: inline-block;
    margin: .5em 2em;
  }
}

.rsp-tiny {
  background: #cb0;
  width: 35.5em;
}

.rsp-sm {
  background: #e33;
  width: 48em;
}

.rsp-md {
  background: #d3d;
  width: 64em;
}

.rsp-lg {
  background: #33d;
  width: 100%;
}
// - - -
