.teams-list {

  border-top: solid $border-width $border-secondary-colour;

  li {
    border-bottom: solid $border-width $border-secondary-colour;
    padding: .5em 0;

    .pure-g {
      margin: .5em 0;
    }
  }

  .teams-list-name {
    font-weight: bold;
  }

}
