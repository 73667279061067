.auto-completion-list {

  margin: 0;
  padding: 0;

  li {
    border-bottom: solid $border-width $border-secondary-colour;
    cursor: pointer;
    list-style-type: none;
    margin: 0;
    padding: 1.5em .5em;

    &:hover {
      background: $background-secondary;
    }

    &.disabled {
      background: lighten($background-secondary, 10%);
      color: lighten($text-primary-colour, 30%);
      cursor: default;
    }

    .autocomplete-more {
      float: right;
      font-size: $text-small;
    }

  }

}

.auto-completion-details {
  color: $text-subtle;
  font-size: $text-small;
  padding: .5em 1em;
  text-align: right;
}
