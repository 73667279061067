
.popover {

  background: $popover-background;
  cursor: pointer;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-3;

  text-align: center;

  &.popover-transparent-background {
    background: none;
    cursor: default;
  }

  .bc-prompt {
    margin-top: 0;
    width: 100%;
  }

  .prompt-dismiss {
    @include dismiss-button-styling();
  }

  .popover-contents {
    background: $popover-content-background;
    border-radius: 4px;

    box-shadow: $box-shadow-surround $box-shadow-mid;
    cursor: default;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 0;// not working on Firefox :/
    max-width: 30em;
    //TODO: layout should simply adjust to accommodate footer contents
    padding: 1em 2em;

    text-align: left;

    width: auto;
    z-index: $zindex-4;

    &.popover-no-padding {
      padding: 0;
    }

  }

  .popover-footer {
    text-align: center;
    width: 100%;

    .pure-button {
      margin-top: 2em;
    }
  }


  .popover-heading {
    display: block;
    font-weight: bold;
    padding: 1em 0;
    text-align: center;
    width: 100%;
  }


}


// hide scroll bar when popover is active
body.popover-active {
  overflow-y: hidden;
}



@include mq-md {
  .web-context {
    .popover.mobile-fullwidth {
      .popover-contents {
        margin-bottom: 5%;
        top: 5%;
      }
    }

    // HACK - ideally popovers shouldn't be nested...
    .popover .popover {
      .popover-contents {
        margin-bottom: 5%;
        top: 0;
      }
    }
  }
}

@include mq-height-mid {
  .web-context {
    .popover.mobile-fullwidth {
      .popover-contents {
        margin-bottom: 5%;
        top: 5%;
      }
    }

    // HACK - ideally popovers shouldn't be nested...
    .popover .popover {
      .popover-contents {
        margin-bottom: 5%;
        top: 0;
      }
    }

  }
}



// always go full screen on webview
.app-context {
  .popover.mobile-fullwidth {

    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    // in app context heading should display in nav bar
    .popover-heading {
      @include hideme();
    }

    .popover-contents {
      border-radius: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: 0;
      position: fixed;
      right: 0;
      top: 0;
      transform: translate(0, 0);
      width: auto;
    }

  }
}
