.campaigns-step {
  border-bottom: solid $border-width $border-primary-colour;
  border-top: solid $border-width $border-primary-colour;
  min-height: 3em;
  padding: $default-padding;
  position: relative;

  &.inactive {
    opacity: .5;
  }

  &.complete {
    opacity: 1;

    &::before {
      color: $colour-positive;
      content: '\f00c';
      font-family: FontAwesome;
      font-size: $text-large;
      position: absolute;
      right: 1em;
      top: 1em;
    }

    .campaigns-step-number {
      border-color: $colour-positive;
      color: $colour-positive;
    }

    .step-heading {
      color: $colour-positive;
    }

  }


  .campaigns-step-mask {
    background: transparent;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .campaigns-step-number {
    font-family: Verdana;
    font-size: $text-large;
    font-weight: bold;
    height: 1.5em;
    left: .5em;
    line-height: 1.5em;
    position: absolute;
    text-align: center;
    top: .5em;
    width: 1.5em;
  }

  .campaigns-step-number-border {
    border: solid $border-width-bold $text-primary-colour;
    border-radius: 50%;
  }

  .campaigns-step-content {
    margin: 0 2.5em 0 3.5em;

    .step-heading {
      font-weight: bold;
    }

  }


}

@include mq-lt-sm {
  .campaigns-step {

    .campaigns-step-content {
      margin-left: 0;
    }

    .campaigns-step-number {
      display: none;
    }

    // icon remains visible at small sizes...
    &.icon {
        .campaigns-step-number {
            line-height: 1em;
            width: 1em;
            height: 1em;
        }

        .campaigns-step-content {
          margin-left: 2.5em;
        }

        .campaigns-step-number {
          display: block;
        }

    }

  }
}
