.pure-button,
.button-padding {
  padding: .75em 1.25em;
}

.button-padding {
  display: block;
}

a.pure-button-primary,
.pure-button-primary {
  background: $primary-button-background;
  border-radius: 3px;
  color: $primary-button-colour;
  text-transform: uppercase;
}

.clickable,
.button-clear {
  background: transparent;
  color: $nav-button-colour;
  font-weight: bold;
  opacity: .9;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    // box shadow with no 'box' is super ugly!
    box-shadow: none;
    color: darken($nav-button-colour, 10%);
    opacity: 1;
  }

  // disabled state = active
  &.button-enable {

    color: $form-input-disabled-colour;

    &.pure-button[disabled],
    &.pure-button-disabled,
    &.pure-button-disabled:hover,
    &.pure-button-disabled:focus,
    &.pure-button-disabled:active {
      color: $text-primary-colour;
      opacity: 1;
    }
  }


}

.button-wide {
  width: 100%;
}


.button-small {
  font-size: 80%;
  padding: .5em 1em;
}

.button-large {
  font-size: 1.4em;
  &.button-round {
    border-radius: 0.5em;
    padding: 0.4em 0.8em;
  }
}

.button-circle {
  border-radius: 50%;
  padding: .5em;
}

.button-round {
  border-radius: 1em;
  padding: 0.2em 0.4em;
}

.button-grey {
  background-color: #284156;
  opacity: 0.5;
  color: white;
}

.button-expand,
.button-condense {
  &::after {
    color: $text-primary-colour;
    font-family: FontAwesome;
    opacity: .5;
  }
}

.button-expand {
  &::after {
    content: ' \f13a';
  }
}

.button-condense {
  &::after {
    content: ' \f139';
  }
}


.button-sub-label {
  display: block;
  font-size: $font-size-small;
  margin-top: $padding-small-vertical;
}

.button-tab {
  background: darken($primary-button-colour, 10%);
  border-bottom: solid 2px darken($primary-button-colour, 20%);
  border-left: solid 2px darken($primary-button-colour, 20%);
  border-right: solid 2px darken($primary-button-colour, 20%);
  border-radius: 0;
  box-sizing: border-box;
  font-size: 110%;
  padding: .5em .25em;
  position: relative;
  text-transform: uppercase;


  &:disabled {
    color: $link-primary-colour;
    opacity: 1;

    &::before {
      border-bottom: solid 4px $link-primary-colour;
      border-left: none;
      border-right: none;
      bottom: 0;
      content: ' ';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

@include mq-tiny {
  .pure-form {

    button[type="submit"] {
      // suppress undesired styling in pure-css
      // margin-top: 0;
    }
  }
}
