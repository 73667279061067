@include mq-tiny {

  // hidden element - tracks current active media query
  #bc-resp-div {
    width: 0;
  }

  .hidden-tiny {
    @include hideme();
  }

  .hidden-tiny-sm {
    @include hideme();
  }

  .hidden-tiny-md {
    @include hideme();
  }

  // for consistency - and we may introduce larger sizes later ;)
  .hidden-tiny-lg {
    @include hideme();
  }


  .content-wrapper {
    // padding-bottom: 10em;

    header,
    .main {
      padding: 1em .5em;
    }
  }


  .padding-small-sm {
    padding: .5em;
  }


  .footer.mobile-sticky {
    background: $background-secondary;
    border-top: solid $border-width lighten($border-secondary-colour, 15%);
    bottom: 0;
    left: 0;

    padding: 0;

    position: fixed;
    text-align: center;
    width: 100%;
  }



}
