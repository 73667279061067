.team-members-list {

  margin-bottom: 2em;

  li:first-child {
    border-top: solid $border-width $border-primary-colour;
  }

  li {
    border-bottom: solid $border-width $border-primary-colour;
    padding: 1em 2em;
  }

  .pure-g .pure-u-1-2:nth-child(even) {
    text-align: right;
  }

}
