@import './bc-app-promo/bc-app-promo';
@import './bc-finn-promo/bc-finn-promo';

.promo-container {

  margin-bottom: $default-bottom-margin;
  margin-top: $default-top-margin;
  text-align: left;
  font-size: $text-small;

  background-color: white;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.3);
  min-height: 176px;
  max-width: 319px;

  box-sizing: border-box;
  padding-left: 32%;

  display: flex;
  flex-direction: column;
  flex-flow: flex-end;

  p {
    color: $text-primary-colour;
    text-align: left;
    font-size: $text-standard;
    line-height: normal;
    margin-top: 3em;
    margin-bottom: auto;
    padding-right: 2em;
  }

  button {
    color: $primary-button-background;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    text-transform: uppercase;

    &::after {
      content: "›";
      font-size: 1.5em;
      display: inline-block;
    }

    &:hover {
      color: $primary-button-background;
    }
  }

 .promo-headline {
   font-weight: 500;
 }

 .promo-tagline {
   font-family: "Lato",sans-serif;
   font-weight: 400;
 }

}

.finn-promo {
  .promo-tagline {
    margin-left: -115px;
    margin-right: 115px;
    margin-top: -20px;
  }

  button {
    margin-left: -115px;
  }
}
