.bc-register-prompt {

  // any defaults go here...

  &.bc-top-panel {
    background-image: url('https://api.bikecitizens.net/system/backgrounds/images/000/000/001/original/free_background_1_fallback.jpg?1489402542');
    color: $primary-button-colour;
    display: block;
    margin-bottom: 10px;
    padding: 12px 20px 7px;
    position: relative;
    text-shadow: 1px 1px 1px rgba(66, 66, 66, .25);

    .info {
      color: $primary-button-colour;
      font-weight: bold;
    }

    .register-prompt-login {
        position: absolute;
        right:20px;
        bottom: 12px;
        padding: 4px;
        margin: 0;
        max-width: 50%;
        text-align: right;

        .register-prompt-login-text{
          display: block;

          font-style: italic;
          margin-bottom: 5px;
        }

        a {
          display: block;
        }
    }


    .register-prompt-login-link {
      color: $primary-button-colour;
      text-transform: uppercase;
      text-decoration: none;

      font-size: $text-medium;
    }

  }
}
