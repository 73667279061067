.app-context {

  .app-hidden {
    @include hideme();
  }

  .add-top-margin {
    margin-top: $default-top-margin;
  }

  header {
    height: 70px;
    padding: .75em;
    text-align: center;

    h1,
    h2,
    h3 {
      font-size: 28px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .footer {
    background: $background-secondary;
    border-top: solid $border-width $border-secondary-colour;
    bottom: 0;
    height: 55px;
    left: 0;
    margin-top: 5em;
    position: fixed;
    text-align: right;
    width: 100%;
    z-index: $zindex-2;

    .nav-button {
      background: transparent;
      border: 0;
      color: $nav-button-colour;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 1em;
      outline: none;
      padding: 1em;
      text-decoration: none;
      text-transform: uppercase;
    }


    .mega-button {
      background-color: $primary-button-background;
      border-radius: 0;
      bottom: 0;
      color: $primary-button-colour;
      display: block;
      height: 55px;
      position: absolute;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;

    }

    .navigation-list {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        list-style-type: none;
      }
    }
  }


  month-selector {
    text-align: center;
  }

  bc-ranking {

    .align-left {
      text-align: left;
    }

    .align-right {
      text-align: right;
    }
  }

  // .pure-form button[type=submit] {
  //  margin-top: 0; // HACK!!
  // }

  h2 {
    font-size: 1.2em;
    margin: .2em auto;
  }


}


@include mq-tiny {
  .app-context {
    .footer {
      font-size: 80%;
      height: 48px;
      // ensure buttons etc. don't wrap out of view!
      white-space: nowrap;
    }
  }
};
