.metric {
  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .center {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .big {
    font-size: 32pt;
    margin: 10px;
  }

  .progress {
    position: relative;
    padding: 40px 0;
    min-height: 100px;
  }

  .icon-bike,
  .progress-text,
  .bc-progress-bar,
  .completed,
  .failed {
    box-sizing: border-box;
    display: inline-block;
  }

  .icon-bike {
    width: 20%;
    height: 100px;
    float: left;
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;

    &.blue {
      background-image: url('/images/campaigns/bremen-moon/Icon_01_Bike.svg');
    }

    &.red {
      background-image: url('/images/campaigns/bremen-moon/Icon_07_Bike_red.svg');
    }
  }

  .progress-text {
    width: 79%;
    padding: 0 10px;
  }

  .bc-progress-bar {
    width: 79%;
    color: #000000;
    padding: 0 10px;

    h3 {
      text-align: left;
      margin-top: 0;
    }

    .progressText,
    .subText {
      font-weight: normal;
    }

    .outer {
      border: none;
      border-radius: 0;
      background-color: #c8ecfb;
    }

    .inner {
      background-color: #5cc4f7;
    }
  }

  .completed,
  .failed {
    width: 79%;
    min-height: 100px;
    padding: 0 0 0 10px;

    h3 {
      margin-top: 0;
    }

    .success-text {
      color: #5cc4f7;
      font-weight: bold;
    }

    .failed-text {
      color: #e3000b;
      font-weight: bold;
    }

    .icon {
      margin-left: 20px;
      height: 100px;
      width: 20%;
      float:right;
      background-position: top right;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .icon-check {
      background-image: url('/images/campaigns/bremen-moon/Icon_06_Checkmark.svg');
    }

    .icon-failed {
      background-image: url('/images/campaigns/bremen-moon/Icon_08_X.svg');
    }
  }
}
