@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Exo-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 500;
	src: url(../fonts/Exo-normal-500.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

