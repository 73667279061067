

.pure-form input[type="password"],
.pure-form input[type="email"],
.pure-form input[type="url"],
.pure-form input[type="date"],
.pure-form input[type="month"],
.pure-form input[type="time"],
.pure-form input[type="datetime"],
.pure-form input[type="datetime-local"],
.pure-form input[type="week"],
.pure-form input[type="tel"],
.pure-form input[type="color"],
.pure-form input[type="number"],
.pure-form input[type="search"],
.pure-form input[type="text"],
.pure-form select,
.pure-form textarea {
  box-shadow: none;
}

form,
.pure-form {
  .input-field-note {
    width: 90%;
    text-align: right;

    a {
      color: gray;
      font-weight: normal;
      font-size: $text-small;
    }
  }

  .one-city-msg {
    color: $form-label-colour;
  }

  .one-city-city-name {
    color: white;
    background-color: $bc-red;
    border-radius: 1em;
    padding: .2em .4em;
  }

  // use on single line form - e.g. with single line of controls
  &.form-no-margin {
    margin: 0;

    .pure-control-group {
      margin: 0;
    }
  }

  &.form-small-margins {
    margin: .5em 0;

    .pure-control-group {
      margin: .5em 0;
    }
  }

  .input-field[disabled] {
    opacity: .5;
  }


  .form-input {
    margin-bottom: 2em;

    //HACK
    @include placeholder {
      font-size: 80%;
    }

    input,
    select {
      border-radius: 3px;
      max-width: 400px;
      padding: .5em;
      width: 90%;
    }

    input {
      line-height: 1.5em;
    }

    [readonly] {
      background: $box-background-secondary;
      border: 0;
      color: $text-primary-colour;
      opacity: .5;
    }

    label {
      color: $form-label-colour;
      margin-bottom: .75em;
      text-align: left;
    }


    // avoid inheriting width: 90%!
    .checkbox-left,
    .checkbox-right {
      width: auto;
    }
  }

// adapted from: http://codepen.io/Vestride/pen/dABHx
  .checkbox-left,
  .checkbox-right {
    opacity: 0;
    position: absolute;

    + label {
      cursor: pointer;
      display: inline-block;
      padding: 0;
      position: relative;

      &::before,
      &::after {
        background: $form-checkbox-background-colour;
        border: solid 2px $form-checkbox-border-colour;
        border-radius: 4px;
        color: $primary-button-colour;
        display: inline-block;
        height: 20px;
        padding-bottom: 4px;
        text-align: center;
        vertical-align: middle;
        width: 20px;
      }

    }


    &:focus + label,
    &:hover + label {
      &::before,
      &::after {
        background-color: $form-checkbox-hover-colour;
      }
    }

    &:checked + label {
      &::before,
      &::after {
        background: $form-checkbox-selected-colour;
      }
    }

    // Disabled state
    &.disabled + label,
    &[disabled] + label {
      color: lighten($form-input-disabled-colour, 20%);
      cursor: default;

      &::before,
      &::after {
        background: white !important;
        border-color: lighten($form-checkbox-border-colour, 30%);
      }
    }

  }

  .checkbox-right {
    + label::after {
      content: '';
      margin-left: 10px;
    }

    &:checked + label::after {
      content: '\2714';
    }
  }


  .checkbox-left {
    + label::before {
      content: '';
      margin-right: 10px;
    }

    &:checked + label::before {
      content: '\2714';
    }
  }

  .pure-controls {
    margin-left: 0;
  }

  &.confirm-data {


    .form-input {

      label {
        margin-bottom: 0;
      }

      input {
        padding-left: 0;
      }

      [readonly] {
        background: transparent;
      }
    }
  }


  // VALIDATION
  .ng-invalid.ng-untouched {
    //!important appears to be required here in order to override angular default styling
      border: solid $border-width $form-input-border-colour !important;
  }


  &.form-was-submitted {
    .ng-invalid {
      border: solid $border-width $colour-negative  !important;
    }
  }

  .ng-invalid.ng-touched {
    border: solid $border-width $colour-negative;
  }


  .input-feedback {
    color: $text-subtle;
    font-size: 90%;
    margin: .5em 0;

    .pure-button.button-clear {
      margin: 0;
      padding: .3em 0;
    }
  }

}



@include mq-md {

  .pure-form {

    .form-input {
      label {
        // margin-bottom: .75em;
        text-align: right;
      }
    }

    .pure-u-md-1-2 {
      .form-input {
        label {
          // margin-bottom: .75em;
          text-align: left;
        }
      }
    }

  }

  .max-width-sm {
    .pure-form {
      .form-input {
        label {
          text-align: left;
        }
      }
    }
  }

}


@include mq-xl {

  .pure-form {

    .form-input {
      label {
        // margin-bottom: .75em;
        text-align: right;
      }
    }

    .pure-u-md-1-2 {
      .form-input {
        label {
          // margin-bottom: .75em;
          text-align: right;
        }
      }
    }

  }

}

.form-narrow {

    .form-input {
      label {
        // margin-bottom: .75em;
        text-align: left;
      }

    }

}
