.sprite {
  background-image: url('/images/icons/appstore-sprites.png');
  width: 144px;
  height: 43px;
  display: inline-block;
  &.spite-appstore-de {
    background-position: 0px 0px;
  }
  &.spite-appstore-en {
    background-position: 0px -45px;
  }

  &.sprite-googleplay-de {
    background-position: 0px -90px;
  }
  &.sprite-googleplay-en {
    background-position: -146px 0px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .sprite {
    background-image: url('/images/icons/appstore-sprites@2x.png');
    background-size: 290px 133px;
  }
}

