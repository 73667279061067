@font-face {
  font-family: 'D-DIN Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('D-DIN Condensed'), url('/fonts/D-DINCondensed.woff') format('woff');
}

.md-tooltip {
  background-color: rgba(0, 0, 0, 0.9) !important;
  height: auto;
  font-size: 11pt;
  font-weight: bolder;
}

.statistics {
  @import './bar-chart/_bar-chart';
  @import './box-plot-chart/_box-plot-chart';
  @import './growth-chart/_growth-chart';
  @import './line-chart/_line-chart';
  @import './split-chart/_split-chart';
  @import './_variables';

  $font: 'D-DIN Condensed';

  .top-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background-color: #111;
    padding: 5px;
    color: #fff;

    .bc-logo-abbr {
      background-image: url('/images/icons/bc-abbr.png');
      background-size: contain;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .log-out-button {
      background-color: #a42619;
      height: 100%;
      color: white;
      border: none;
      padding: 4px 8px;
      margin-left: auto;
      border-radius: .2em;

      &:hover {
        background-color: #b43325;
      }
    }
  }

  .header {
    max-width: 820px;
    margin: 10px auto;

    form#city-statistics-select {
      display: block;
      position: relative;
      color: #444;

      .form-input {
        margin-bottom: 0;
        width: 33%;
        display: inline-block;

        select {
          width: 100%;
          color: #444;
          line-height: 1.3;
          padding: .6em 1.4em .5em .8em;
          box-sizing: border-box;
          margin: 0;
          border: 1px solid #aaa;
          box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
          border-radius: .4em;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-color: #fff;
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23444444%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat, repeat;
          background-position: right .7em top 50%, 0 0;
          background-size: .65em auto, 100%;
        }
      }

      div.date {
        display: inline-block;
        width: 32%;
        text-align: center;
      }
    }
  }

  .overlay-wrapper {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;



    .overlay {
      z-index: 99;
      position: relative;
      width: 90%;
      max-height: 80%;
      top: 50px;
      left: 5%;
      background-color: rgba(255,255,255,0.95);
      border: 2px solid black;
      padding: 40px 20px 80px;
      overflow-y: scroll;

      h3 {
        font-family: $font;
        margin-bottom: 50px;
      }

      .achievements {
        width: 440px;
        margin: auto;
      }

      .benefits {
        width: 800px;
        margin: auto;
        padding-bottom: 80px;

        table {
          width: 100%;

          .p, .b {
            text-align: left;
          }

          .c, .t {
            text-align: right;
          }

          .p, .c {
            padding: 8px 0;
          }

	  .t {
	    padding: 8px 10px;
	  }

          .b {
            padding: 8px 10px;
          }
        }
      }
    }
  }

  .users,
  .tracks {
    width: 820px;
  }

  .brown {
    color: $brown;
  }

  .red {
    color: $red;
  }

  .green {
    color: $green;
  }

  .loading {
    filter: blur(2px);
  }

  .flex-row,
  .flex-column {
    display: flex;

    & > div {
      flex-grow: 1;
    }

    &.space-between {
      justify-content: space-between;

      & > div {
        flex-grow: 0;
      }
    }
  }

  .flex-column {
    flex-direction: column;
  }

  h2 {
    font-size: 72pt;
    text-transform: uppercase;
    margin: 0;
  }

  h3 {
    font-size: 32pt;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  .carousel {
    position: relative;

    .controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
      }
    }

    .slide {
      height: 200px;

      .heading {
        text-align: center;
        text-transform: uppercase;
        font-size: 14pt;
      }
    }

  }

  .cpis {
    padding: 10px 3vw;
    display: flex;
    justify-content: space-between;
  }

  .chart-box {
    border: 1px solid rgba(0,0,0,0.05);
    padding: 20px;
    margin: 0;
    font-family: $font;
    position: relative;

    .maximize {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18pt;
      font-weight: bold;
      padding: 0 4px 2px;
      color: $red;

      &:hover {
        color: white;
        background-color: $red;
      }
    }
  }

  .sliders {
    display: flex;

    .slider,
    .buttons {
      width: 220px;
    }

    .label {
      font-size: 17pt;
      padding-top: 14px;
      padding-left: 10px;

      &.no-padding {
        padding-top: 0;
      }
    }

    .rzslider {
      .rz-bar-wrapper {
        margin-top: -25px;
      }

      .rz-pointer {
        width: 14px;
        height: 14px;

        &:after {
          display: none;
        }
      }
    }

    .buttons {
      display: flex;

      button {
        width: 33%;
        padding: 0;
        border-width: 1px;
        border-color: $blue;
        background-color: #ffffff;

        &:hover {
          background-color: $blue-light;
        }

        &.active {
          background-color: $blue;
          color: #ffffff;
        }

        &:first-of-type {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-of-type {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .users {
    color: $blue;

    .users-top {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 380px;

      h2 {
        text-align: right;
      }
    }

    .total-active-users {
      margin-left: auto;

      .value {
        font-size: 48pt;
        display: block;
      }

      .label {
        font-size: 24pt;
      }
    }

    .total-users {
      border: 1px solid $blue;
      margin-top: 16px;
      padding: 10px 5px 5px;
      text-align: center;
      font-size: 14pt;

      .number {
        font-size: 26pt;
      }
    }

    .users-active {
      width: 150px;
      height: 150px;
      margin-left: 30px;
      position: relative;

      .user-active-value {
        position: absolute;
        font-size: 18pt;

        &.active {
          bottom: 10px;
          right: -10px;

          text-shadow:
            -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
        }

        &.inactive {
          top: 10px;
          left: -20px;
          color: #fff;

          text-shadow:
            -1px -1px 0 $blue,
            1px -1px 0 $blue,
            -1px 1px 0 $blue,
            1px 1px 0 $blue;
        }
      }

      .total {
        display: block;
        text-align: center;
        padding-top: 20px;
      }
    }

    .users-tpw {
      .label {
        margin-top: -7px;
      }
    }

    #userGrowthChart {
      margin-top: -40px;
      margin-bottom: 11px;
      bottom: 20px;
      height: 180px;
      width: 300px;
    }
  }

  .gender-chart {
    position: relative;
    display: inline-block;
    width: 220px;
    height: 220px;

    .chart {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .gender-coverage {
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 40%;
      width: 30%;
      left: 35%;

      .value {
        font-size: 18pt;
      }

      .label {
        font-size: 10pt;
      }
    }

    .gender-value {
      position: absolute;
      font-size: 32pt;

      &.f {
        top: 10px;
        right: 0;

        text-shadow:
          -1px -1px 0 #fff,
          1px -1px 0 #fff,
          -1px 1px 0 #fff,
          1px 1px 0 #fff;
      }

      &.m {
        bottom: 20px;
        left: 0;
        color: #fff;

        text-shadow:
          -1px -1px 0 $blue,
          1px -1px 0 $blue,
          -1px 1px 0 $blue,
          1px 1px 0 $blue;
      }
    }
  }

  .user-profiling {
    color: $blue;

    select {
      display: block;
      margin-bottom: 20px;
      text-transform: uppercase;

      option {
        font-family: 'Arial';
      }
    }

    .profiling-result {
      display: inline-block;
      width: 29%;
      text-align: center;
      position: relative;
      bottom: 10%;

      .tracks-result {
        color: $brown;
      }

      .big {
        font-size: 52pt;
        line-height: 54pt;
        display: inline-block;
      }

      .small {
        font-size: 12pt;
        padding-bottom: 20px;
        display: inline-block;
      }
    }
  }

  .tracking-navigation,
  .split-chart {
    font-family: $font;
    text-transform: uppercase;
    font-size: 32pt;

    .left {
      color: $brown;
    }

    .right {
      color: $purple;
    }

    .chart {
      font-size: 29pt;

      .values {
        margin-bottom: 10px;
      }
    }

    .title,
    .values,
    .labels {
      display: flex;
      justify-content: space-between;
    }

    .label {
      font-size: 14pt;
    }

    .bar {
      height: 25px;
      display: flex;

      .left,
      .right {
        height: 100%;
        width: 50%;
        transition: width 1s;
      }

      .left {
        border-right: 5px solid white;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: -12px;
          top: -15px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 7.5px 0 7.5px;
          border-color: $purple transparent transparent transparent;
        }
      }

      .right {
        border-left: 5px solid white;
      }
    }

    .labels {
      margin-top: 5px;
      font-size: 14pt;
    }
  }

  .split-chart {
    margin-top: 20px;

    .bar {
      position: relative;

      .left, .right {
        .value {
          color: #ffffff;
          font-size: 12pt;
          position: absolute;
          top: 4px;
        }
      }

      .left .value {
        left: 2px;
      }

      .right .value {
        right: 2px;
      }
    }

    .labels {
      text-transform: none;
      font-size: 12pt;
    }
  }

  .tracking-navigation {
    .bar {
      .left {
        background-color: $brown;
      }

      .right {
        background-color: $purple;
      }
    }
  }

  .user-averages,
  .routing-profiles,
  .bike-types,
  .companies-averages,
  .team-averages,
  .track-averages {
    display: inline-block;

    .values {
      display: flex;

      .value {
        text-align: center;
        width: 30%;
        padding: 10px 2px;

        &:not(:last-child) {
          margin-right: 6px;
        }

        .number {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 32pt;
        }

        .label {
          font-size: 12pt;
        }
      }
    }
  }

  .user-averages {
    width: 60%;

    .values {
      .value {
        border: 1px solid $blue;
        width: 23%;
      }
    }
  }

  .companies-averages {
    .values {
      .value {
        border: 1px solid $green;
        width: 23%;
      }
    }
  }

  .team-averages {
    .values {
      .value {
        border: 1px solid $red;
        width: 23%;
      }
    }
  }

  .track-averages {
    .values {
      .value {
        border: 1px solid $brown;
        width: 23%;
      }
    }
  }

  .bike-types {
    width: 40%;

    .values {
      .value {
        background-color: $blue;
        color: #ffffff;
        width: 30%;
      }
    }
  }

  .user-profiling {
    width: 55%;
  }

  .tracking-navigation {
    width: 45%;

    .routing-profiles {
      display: flex;
      text-transform: uppercase;
      margin-top: 20px;

      .title {
        flex-basis: 25%;
        flex-grow: 1;
        color: $purple;
        font-size: 22pt;
        padding-right: 5px;
      }

      .values {
        flex-grow: 1;
        flex-basis: 75%;

        .value {
          background-color: $purple;
          color: #ffffff;
        }
      }
    }
  }

  .user-streaks {
    .weeks,
    .days {
      position: relative;
      width: 380px;
    }

    .weeks {
      margin-right: 10px;
    }

    .days {
      margin-left: 10px;
    }

    .switch {
      position: absolute;
      top: -10px;
      right: 4px;
      color: $grey;
      font-size: 9pt;

      .active,
      :hover {
        color: $blue;
      }

      button {
        padding: 3px 7px;
        border-width: 1px;
        border-color: $blue;
        background-color: #ffffff;

        &:hover {
          background-color: $blue-light;
        }

        &.active {
          background-color: $blue;
          color: #ffffff;
        }

        &:first-of-type {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-of-type {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    .title {
      position: absolute;
      bottom: -14px;
      width: 100%;
      text-align: center;
      color: $brown;
      letter-spacing: 1.4px;
    }
  }

  .peak-hour-riders {
    padding-bottom: 35px;

    .peak-hour-charts {

      > div {
        width: 30%;
        margin: 0 2px;
      }

      .chart {
        position: relative;

        .label {
          position: absolute;
          bottom: -20px;
          display: block;
          width: 100%;
          text-align: center;
          font-size: 13pt;
        }
      }
    }
  }

  .tracks {
    .tracks-top {
      height: 380px;
    }

    .total-tracks {
      font-size: 36pt;
    }

    .total-km span {
      font-size: 26pt;
      border: 1px solid $brown;
      padding: 10px;
      position: relative;
      top: 7px;
    }

    .tpd-chart {
      width: 335px;
      height: 214px;
      margin-top: 37px;
    }

    .trip-length-chart,
    .tph-chart {
      width: 350px;
      height: 195px;
      position: relative;

      .heading {
        position: absolute;
        font-size: 18pt;
        right: 0;
      }
    }

    .trip-length-chart .heading {
      top: -18px;
    }

    .tph-chart {
      margin-top: 3px;

      .heading {
        bottom: -9px;
      }
    }

    .x-label {
      position: absolute;
      bottom: 38px;
      right: -10px;
      font-size: 10pt;
    }
  }

  .layout-bbs {
    .users-top,
    .tracks-top {
      height: 407px;
    }

    .total-dl {
      text-transform: uppercase;
      font-size: 22pt;
      position: relative;

      .value {
        font-weight: bold;
        float: right;
        font-size: 42pt;
        margin-left: 10px;
      }

      p {
        margin: 0;
      }
    }

    .app-dl {
      .growth-chart {
        width: 240px;
      }
    }

    .participants-box-plots {
      position: relative;
      top: 10px;
      height: 401px;
      padding: 0 18px;
      text-align: center;
    }

    .participants-vs-tracks .growth-chart {
      width: 220px;
    }

    .total-participants {
      padding-left: 10px;
      font-size: 22pt;

      .value {
        font-weight: bold;
        font-size: 32pt;
      }
    }

    .users-tpw {
      position: relative;
      margin-top: 27px;
      width: 400px;
    }

    .user-profiling {
      .slider,
      .buttons {
        width: 280px;
      }

      .profiling-result {
        display: block;
        bottom: auto;
        width: auto;
        margin-top: 20px;

        .value {
          display: inline-block;
          padding: 20px;
        }
      }
    }

    .finneros-collected,
    .benefits-claimed {
      flex: 0 1 auto;

      .growth-chart {
        width: 370px;
      }
    }

    .achievements {
      flex: 1 0 auto;
    }

    .top-benefits {
      height: 274px;

      .bar-chart {
        margin-top: 20px;
        width: 370px;
        height: 220px;
      }

      .label {
        margin-top: 8px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .total-tracks {
      margin-top: 13px;
    }

    .tracks-yesterday {
      margin: 10px 0 0;

      .chart {
        width: 60px;
        margin: 0 10px;
      }
    }

    .trip-length-chart {
      margin-top: 10px;

      .heading {
        top: -14px;
      }
    }

    .tph-chart {
      margin-top: 25px;

      .heading {
        bottom: -18px;
      }
    }

    .user-averages {
      width: auto;
      flex: 0 1 auto;

      .value {
        border: 1px solid $brown;
        padding: 10px 0;
      }
    }

    .tracking-navigation {
      width: auto;
    }
  }

  .layout-b2w {
    .participants-total {
      margin-left: 40px;

      span {
        display: block;
        text-align: right;

        &.total {
          font-size: 52pt;
        }

        &.today {
          font-size: 20pt;
        }
      }
    }

    .user-averages {
      width: auto;
    }

    .work-leisure {
      .title {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        font-size: 32pt;
        font-weight: bold;

        .left {
          color: $green;
        }

        .right {
          color: $purple;
        }
      }
    }

    .users-tpw,
    .wau-chart,
    .wat-chart,
    .team-sizes {
      .heading {
        font-size: 20pt;
        text-transform: uppercase;
        margin: 0 0 10px;
      }

      .label {
        font-size: 16pt;
        margin-top: -7px;
      }
    }
  }

  .custom-dates-overlay {
    position: absolute;
    width: 96%;
    left: 2%;
    top: 2%;
    background-color: white;
    max-height: 96%;
    overflow-y: scroll;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 32pt;
      color: red;
      padding: 4px 12px 0;

      &:hover {
        background-color: red;
        color: white;
      }
    }

    .content {
      padding: 50px 20px 20px;
    }

    .custom-timespan {
      display: block;
      width: 100%;
    }

    .datepickers {
      display: block;
      text-align: center;

      span {
        margin-left: 20px;
      }
    }
  }
}
