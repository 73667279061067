@import '../_variables';

.split-chart {
  display: flex;
  justify-content: space-between;

  .icon-wrapper {
    flex: 0 0 36px;
    position: relative;

    .icon {
      max-width: 26px;
      height: auto;
      position: absolute;
      left: 0;
      top: -8px;
    }
  }

  .bar-wrapper {
    position: relative;
    flex: 1 1 auto;
  }

  .bar {
    .left,
    .right {
      .value {
        &.blue {
          color: $blue;
        }

        &.white {
          color: #ffffff;
        }
      }
    }

    .marker {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: -7px;
        top: -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 7.5px 0 7.5px;
        border-color: inherit;
      }
    }
  }

  .labels {
    &.labels-to-marker {
      span {
        position: absolute;
        top: -16px;
        font-weight: bold;
      }
    }
  }

  .description {
    text-transform: none;
    font-size: 14pt;
    font-weight: bold;
    padding: 5px 0 10px;
  }
}
