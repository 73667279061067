
// team search/selection should be moved to an angular component
.team-search-result {
  border-bottom: solid $border-width $border-secondary-colour;
}


.bc-calendar-table {
  border-collapse: collapse;
  table-layout: fixed;

  width: 100%;

  th,
  td {
    // border: solid 1px #eee;
    padding: .5em 0;
    text-align: center;
    width: 14.285%;
  }

  td {

    height: 76px;
    position: relative;
    text-align: center;
    vertical-align: top;

    &:hover {
      background: darken(#fff, 5%);
    }

    span {
      display: block;
    }

    &.inactive {
      opacity: .5;
    }

  }

}


.calendar-month-view {
  margin-top: 2em;
}
