.list-plain {

  margin: 0;
  padding: 0;

  li {
    list-style-image: none;
    list-style-type: none;
  }
}

.list-inline {
  li {
    display: inline-block;
  }
}
